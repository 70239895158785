<template>
  <div class="thirdPrivacy">
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">第三方数据共享</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >洋葱教育第三方数据共享说明</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >为保障产品和服务的稳定运行或实现相关功能</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您的个人信息可能会在各服务提供方之间共享</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将根据法律法规要求并参照行业最佳实践</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >不断完善和提升对您个人信息的安全保障水平</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >我们严格遵循隐私权政策的约定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >一旦涉及共享您的个人敏感信息或新增共享方</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将再次征求您的授权同意</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >具体共享情况说明如下</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >深圳市和讯华谷信息技术有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">共享方式</span
      ><span style="font-family: 'Courier New'">： SDK （</span
      ><span style="font-family: 'Microsoft JhengHei'">极光推送</span
      ><span style="font-family: 'Courier New'"> SDK）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">使用目的</span
      ><span style="font-family: 'Courier New'">： </span
      ><span style="font-family: 'Microsoft JhengHei'">用于实现消息推送</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">或其他推送</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'">功能</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">数据类型</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">地理位置</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网络信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">隐私政策链接</span
      ><span style="font-family: 'Courier New'"
        >： https://www.jiguang.cn/license/privacy</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <div style="height: 20px"></div>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >深圳市腾讯计算机系统有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">共享方式</span
      ><span style="font-family: 'Courier New'">： SDK （</span
      ><span style="font-family: 'Microsoft JhengHei'">微信分享</span
      ><span style="font-family: 'Courier New'"> SDK）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">使用目的</span
      ><span style="font-family: 'Courier New'">： </span
      ><span style="font-family: 'Microsoft JhengHei'">分享应用</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">数据类型</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">设备标识信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Microsoft JhengHei'">隐私政策链接</span
      ><span style="font-family: 'Courier New'"
        >：
        <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
          https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy
        </p>
      </span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <div style="height: 20px"></div>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      极光认证SDK
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      功能：用于实现App一键登录，号码认证的功能
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      收集个人信息的类型：设备信息、位置信息、网络信息、SDK认证相关请求结果、手机号码。
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      极光隐私政策链接：https://www.jiguang.cn/license/privacy
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      设备信息：设备标识符（IMEI、IDFA、Android
      ID、IMSI、MAC、OAID相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      网络信息：IP地址，WiFi信息，基站信息
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <div style="height: 20px"></div>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">BuglySDK</p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      功能：用于APP程序各类异常问题的监察，当发生异常崩溃的情况下会收集到崩溃信息上传到开发者的Bugly账号下
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      收集个人信息的类型：手机设备相关信息，主要包括硬件型号、设备MAC地址、唯一设备识别码（如IMEI/MEID/android
      ID/SN/OPENUDID/GUID、SIM卡IMSI信息在内的描述个人常用设备基本情况的信息）、设备型号、设备制造商、操作系统及其版本等。
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      隐私政策链接：https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf
    </p>
    <div style="height: 20px"></div>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">本应用使用( com.facebook.fresco SDK )</p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      官网链接：https://www.fresco-cn.org/
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      使用目的：加载图片时使用 
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      不会对个人信息进行共享。
    </p>
    <!-- <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      设备信息：设备标识符（IMEI、IDFA、Android
      ID、IMSI、MAC、OAID相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 10.5pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="font-size: 10.5pt; font-family: 'Microsoft JhengHei'">
      网络信息：IP地址，WiFi信息，基站信息
    </p> -->
  </div>
</template>

<script>
export default {
  name: "thirdPrivacy",
};
</script>

<style>
.thirdPrivacy {
  padding: 10px;
}
</style>